button.avatar-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: relative; /* if the avatar button is positioned inside a message bubble */
  outline: none; /* be careful with this as it can affect accessibility */
}

button.avatar-button:focus {
  outline: none; /* this removes the focus outline, but be aware that it can affect accessibility */
}